var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "30%",
        "close-on-click-modal": false,
        visible: _vm.showDialogInternal,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialogInternal = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("h3", [_vm._v("Recipients")]),
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      gap: "5px",
                      "justify-content": "flex-end",
                      "padding-top": "20px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { disabled: _vm.disableForm },
                        on: { click: _vm.addRecipient },
                      },
                      [_vm._v("Add")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { disabled: _vm.disableForm, type: "primary" },
                        on: { click: _vm.saveRecipients },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formDataRef",
                  attrs: { model: _vm.formData, disabled: _vm.disableForm },
                },
                _vm._l(_vm.formData.recipients, function (recipient, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { display: "flex", gap: "10px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "Email",
                            prop: "recipients." + index + ".email",
                            rules: _vm.GENERAL_EMAIL_RULE_REQ,
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              model: {
                                value: recipient.email,
                                callback: function ($$v) {
                                  _vm.$set(recipient, "email", $$v)
                                },
                                expression: "recipient.email",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-delete",
                                  circle: "",
                                },
                                on: { click: () => _vm.removeRecipient(index) },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }