<template>
  <el-dialog
    width="30%"
    :close-on-click-modal="false"
    :visible.sync="showDialogInternal" >
    <div v-loading="isLoading">
      <div slot="title" style="display: flex; justify-content: space-between;">
        <h3>Recipients</h3>
        <div>
          <div style="display: flex; gap: 5px; justify-content: flex-end; padding-top: 20px;">
            <el-button :disabled="disableForm" @click="addRecipient">Add</el-button>
            <el-button :disabled="disableForm" type="primary" @click="saveRecipients">Save</el-button>
          </div>
        </div>
      </div>
      <div>
        <el-form ref="formDataRef" :model="formData" :disabled="disableForm">
          <div v-for="(recipient, index) in formData.recipients" :key="index" style="display: flex; gap: 10px;">
            <el-form-item label="Email"
              :prop="'recipients.' + index + '.email'"
              :rules="GENERAL_EMAIL_RULE_REQ"
              style="width: 100%;" >
              <el-input v-model="recipient.email">
                <el-button slot="append" icon="el-icon-delete" circle @click="() => removeRecipient(index)"></el-button>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getJobReceipients, saveJobReceipients } from '@/services/modules/ops.js'
import { GENERAL_EMAIL_RULE_REQ } from '@/constants.js'
export default {
  name: 'EmailRecipients',
  props: {
    jobId: String,
    jobStatus: Number,
    showDialog: Boolean
  },
  data () {
    return {
      GENERAL_EMAIL_RULE_REQ,
      showDialogInternal: false,
      isLoading: false,
      formData: {
        recipients: [

        ]
      }
    }
  },
  mounted () {
    this.getReceipients()
  },
  computed: {
    disableForm () {
      return [4,5].includes(this.jobStatus)
    }
  },
  watch: {
    showDialog (newVal, oldVal) {
      if (newVal) this.showDialogInternal = true
    },
    showDialogInternal (newVal, oldVal) {
      if (!newVal) this.$emit('on-dialog-closed')
    }
  },
  methods: {
    getReceipients () {
      this.isLoading = true
      getJobReceipients(this.jobId).then(res => {
        this.emails = res.data?.map(recipient => recipient.email)
        this.formData.recipients = res.data
      })
      .catch(() => {})
      .finally(() => this.isLoading = false)
    },
    addRecipient () {
      this.formData.recipients.push({ email: ''})
    },
    removeRecipient (index) {
      this.formData.recipients.splice(index, 1)
    },
    saveRecipients () {
      this.isLoading = true
      this.$refs.formDataRef.validate((valid) => {
        if (valid) {
          const payload = this.formData.recipients.map(recipient => recipient.email)
          this.isLoading = true
          saveJobReceipients(this.jobId, payload).then(res => {
            if (res.code === 1000) {
              this.$message.success('Successfully saved the recipients.')
              this.getReceipients()
            }
          })
        } else {
          this.isLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  overflow: auto;
}
</style>
